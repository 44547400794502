import React, { useCallback, useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { AE_SDK_CONTAINER_ID, LandingPage } from '../../components/LandingPage';
import { useAESDKInitialization } from "../../hooks/useAESDKInitialization";
import { getQueryParams } from "../../services/query-params-provider";
import { getHomeTokenFromTemporaryCode } from '../../services/authentication-api';
import { getSDKHandler } from "../../services/promisified-ae-sdk";
import { logger } from '../../services/logger';
import { AE_SDK_API } from "@anywhere-expert-sdk/types";
import { Context, tweekStore } from "../../stores/TweekStore";
import { clearToken, getToken, setToken } from '../../services/persistent-token-storage';

const tryLoginWithTemporaryCode = async (temporaryCode: string, sdkHandler: AE_SDK_API): Promise<string> => {
    const token = await getHomeTokenFromTemporaryCode(temporaryCode);
    if (!token) throw 'failed to get token'
    await sdkHandler.auth.login({ token });
    return token
};

const loginToSdk = async (sdkHandler: AE_SDK_API): Promise<void> => {
    const { code: temporaryCode } = getQueryParams<{ code: string }>();

    if (temporaryCode) {
        try {
            const url = new URL(window.location.href);
            url.searchParams.delete('code');
            window.history.pushState({}, '', url);

            const accessToken = await tryLoginWithTemporaryCode(temporaryCode, sdkHandler);
            setToken(accessToken);
            return;
        } catch (error) {
            logger.error('failed to login with temporary code', error);
        }
    }

    const previouslyUsedAccessToken = getToken();
    if (previouslyUsedAccessToken) {
        try {
            await sdkHandler.auth.login({ token: previouslyUsedAccessToken });
            return;
        } catch {
            clearToken();
        }
    }

    await sdkHandler.auth.login();
};

const FLOW_ID_TO_EXPERTISE_TWEEK_KEY = 'soluto_home_web/messaging/expertise';

const tryToConvertFlowIdToExpertise = async (sdk: AE_SDK_API): Promise<void> => {
    const { f: flowId } = getQueryParams<{ f: string }>();
    if (!flowId) return;

    const newContext: Context = {
        user_browser: {
            FlowId: flowId
        },
        home_user: {
            MessagingLinkFlow: flowId
        }
    };

    tweekStore.updateContext(newContext);
    const expertise = await tweekStore.getFromTweek<string>(FLOW_ID_TO_EXPERTISE_TWEEK_KEY);
    if (!expertise) throw `could not find expertise result from tweek for flow id: ${flowId}`;
    await sdk.appendSettings(settings => ({
        ...settings,
        expertise: [expertise],
    }));
};

export const IVRFlow: FunctionComponent = () => {
    const initSDK = useAESDKInitialization();
    const [isReadyToInitializeTheSDK, setIsReadyToInitializeTheSDK] = useState(false);
    let sdk;

    useEffect(() => {
        if (isReadyToInitializeTheSDK) getSDKHandler()().then(sdk => sdk.appendSettings(_ => ({ ..._, isHidden: false })))
    }, [isReadyToInitializeTheSDK]);

    const initAndLoginToSdkWithTemporaryCode = useCallback(async () => {
        await initSDK({ containerElementId: AE_SDK_CONTAINER_ID, isHidden: true });

        sdk = await getSDKHandler()();
        const convertFlowIdToExpertise = tryToConvertFlowIdToExpertise(sdk)
            .catch(error => {
                logger.error('failed to convert flow id query param to expertise', error);
            })
            .finally(() => {
                setIsReadyToInitializeTheSDK(true);
            });

        await Promise.all([loginToSdk(sdk), convertFlowIdToExpertise]);

        // empty AE SDK container fills up entire screen, so let's remove it 
        document.getElementById('anywhere-expert-sdk')?.remove();
        
        return (sdk.status());
    }, []);

    useEffect(() => {

        initAndLoginToSdkWithTemporaryCode().then(
            (status) => {
                setTimeout(() => { !status.session.sessionId && sdk.sendMessage({ type: 'CustomerMessage', message: 'Hey, can you help me online?' }) }, 1000);
            }).catch(error => {
                logger.error('failed to init ivr flow landing page', error);
            });

    }, []);

    return <LandingPage />;
};
